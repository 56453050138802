.loader {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

@keyframes spin {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

.loader img {
  transform: rotate(45deg);
  width: 20vw;
  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
